<template>
  <v-card color="basil">
    <v-card-title class="text-center justify-center py-6">
      <h1 class="font-weight-bold text-h2 text-basil">
        IATSE 42 Training Site
      </h1>
    </v-card-title>

    <v-tabs
      v-model="tab"
      align-tabs="center"
      color="deep-purple-accent-4"
    >
      <v-tab value="option-1">Introduction</v-tab>
      <v-tab value="option-2">Video</v-tab>
      <v-tab value="option-3">Audio</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">

      <v-tabs-window-item value="option-1">
        <v-card color="basil" flat>
          <v-card-text><p>This is where stuff will go</p></v-card-text>
        </v-card>
      </v-tabs-window-item>

      <v-tabs-window-item value="option-2">
        <v-card color="basil" flat>
          <v-card-text>
              
                <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/935173039?h=5865dec5ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="20230208_sd_IATSE42_Lighting02_FINALmp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
              </v-card-text>
        </v-card>
      </v-tabs-window-item>

      <v-tabs-window-item value="option-3">
        <v-card color="basil" flat>
          <v-card-text><p>More stuff</p></v-card-text>
        </v-card>

      </v-tabs-window-item>
    </v-tabs-window>
    
  </v-card>
</template>
<script>
  export default {
    data: () =>({
      
        tab: '1',
    }),
  }
</script>

<style>
/* Helper classes */
.bg-basil {
  background-color: #FFFBE6 !important;
}
.text-basil {
  color: #356859 !important;
}
</style>